import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Navbar, NavbarBrand } from 'reactstrap'
import { useTranslation } from 'react-i18next'
import { useCookies } from 'react-cookie'
import { logout } from '../../api/AuthAPI'
import { me } from '../../api/AuthAPI'
import { useHistory } from 'react-router-dom'
import { validate } from '../../api/AuthAPI'
import style from './css/MainNavbar.module.css'
import imgLogo from './assets/logo.svg'
import Avatar from 'react-avatar'

const MainNavbar = ({ authToken }) => {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)
  const [, removeCookie] = useCookies(['auth_token'])
  const [, setSubmitting] = useState(false)
  const [user, setUser] = useState('')
  const [, setError] = useState(false)
  const dropdownRef = useRef(null)
  const { t } = useTranslation()
  const history = useHistory()

  useEffect(() => {
    const interval = setInterval(() => {
      validate()
        .then(({ data }) => {
          console.log('session up')
        })
        .catch(error => {
          console.log('session down')
        })
    }, 60000)

    if (authToken) {
      me()
        .then(({ data }) => {
          setUser(data)
        })
        .catch(error => {
          setSubmitting(false)
          setError(true)
        })
    }

    return () => clearInterval(interval)
  }, [authToken])

  const onLogout = e => {
    e.preventDefault()
    setSubmitting(true)
    logout()
      .then(({ data }) => {
        setSubmitting(false)
        removeCookie('auth_token')
        history.push({ pathname: '/login' })
      })
      .catch(error => {
        setSubmitting(false)
        setError(true)
      })
  }

  const handleClickOutside = useCallback(
    event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownIsOpen(false)
      }
    },
    [dropdownRef]
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <Navbar color="dark" expand="lg" className={`${style.navBarMain} navbar-absolute fixed-top`}>
      <div className="navbar-wrapper">
        <NavbarBrand href="/">
          <img alt="logo.png" src={imgLogo} width="100%" />
        </NavbarBrand>
      </div>
      {authToken && (
        <div className={style.dropdown} ref={dropdownRef}>
          <div className={style.dropdownButton} onClick={() => setDropdownIsOpen(!dropdownIsOpen)}>
            <Avatar
              name={user.name}
              size="42"
              round={true}
              color="#9EF8EE"
              fgColor="#348888"
              style={{
                fontSize: '14px',
                fontWeight: '700',
                lineHeight: '16.94px',
              }}
            />
          </div>
          <div className={`${style.dropdownContainer} ${style[String(dropdownIsOpen)]}`}>
            <div className={style.dropdownHeader}>
              <Avatar
                name={user.name}
                size="42"
                round={true}
                color="#9EF8EE"
                fgColor="#348888"
                style={{
                  fontSize: '14px',
                  fontWeight: '700',
                  lineHeight: '16.94px',
                  strokeWidth: '2.211px',
                  stroke: '#F7F7FF',
                }}
              />
              <span>{user.name}</span>
            </div>
            <div className={style.dropdownBody}>
              {/*  <a href="#">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11"
                  height="13"
                  viewBox="0 0 11 13"
                  fill="none">
                  <path
                    d="M10.0104 11.6681C10.0104 12.1011 9.65915 12.4561 9.22419 12.4561H1.34086C0.907749 12.4561 0.554688 12.1023 0.554688 11.6681C0.554688 9.05717 2.67134 6.94038 5.28243 6.94038C7.89353 6.94038 10.0102 9.05722 10.0102 11.6681H10.0104ZM5.28262 7.99108C3.34002 7.99108 1.74938 9.49726 1.61472 11.4056H8.9507C8.81604 9.49726 7.2254 7.99108 5.28281 7.99108H5.28262ZM5.28262 6.41508C3.68701 6.41508 2.39335 5.12161 2.39335 3.52599C2.39335 1.93024 3.68701 0.636719 5.28262 0.636719C6.87823 0.636719 8.17189 1.93019 8.17189 3.52599C8.17189 5.12161 6.87823 6.41508 5.28262 6.41508ZM5.28262 5.36456C6.29796 5.36456 7.12114 4.54138 7.12114 3.52604C7.12114 2.51051 6.29796 1.68733 5.28262 1.68733C4.26728 1.68733 3.4441 2.51051 3.4441 3.52604C3.4441 4.54138 4.26728 5.36456 5.28262 5.36456Z"
                    fill="#68688C"
                    stroke="white"
                    stroke-width="0.131458"
                  />
                </svg>
                <span>Mi perfil</span>
              </a> */}
              <a href="#" onClick={onLogout}>
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
                  <path
                    d="M1.80289 0.438485C1.36271 0.438485 1 0.801199 1 1.24128V11.4107C1 11.8508 1.36271 12.2135 1.80289 12.2135H6.08474C6.15642 12.2145 6.2254 12.1868 6.27645 12.1365C6.32751 12.0862 6.35616 12.0176 6.35616 11.9459C6.35616 11.8743 6.32751 11.8056 6.27645 11.7553C6.2254 11.705 6.15642 11.6772 6.08474 11.6783H1.80289C1.65001 11.6783 1.5353 11.5636 1.5353 11.4107V1.24129C1.5353 1.08841 1.65001 0.973702 1.80289 0.973702H6.08474C6.15642 0.974728 6.2254 0.947007 6.27645 0.896698C6.32751 0.846388 6.35616 0.777692 6.35616 0.706103C6.35616 0.63442 6.32751 0.565817 6.27645 0.515508C6.2254 0.465199 6.15642 0.437479 6.08474 0.438504L1.80289 0.438485ZM7.39367 3.11034C7.2895 3.12042 7.20074 3.19042 7.16658 3.28936C7.13242 3.3882 7.15902 3.49797 7.23472 3.57031L9.72258 6.05818H3.67615C3.66784 6.0578 3.65944 6.0578 3.65113 6.05818C3.5801 6.06144 3.51337 6.0929 3.46549 6.14535C3.4177 6.1979 3.39268 6.26734 3.39595 6.33828C3.39931 6.40931 3.43067 6.47604 3.48322 6.52393C3.53577 6.57171 3.60521 6.59673 3.67615 6.59337H9.72258L7.23471 9.08124C7.17554 9.12893 7.13951 9.19949 7.1354 9.27528C7.13139 9.35117 7.15967 9.42518 7.21343 9.47894C7.2671 9.53261 7.34112 9.56099 7.417 9.55688C7.49288 9.55287 7.56345 9.51674 7.61105 9.45757L10.5548 6.5138C10.658 6.40954 10.658 6.24172 10.5548 6.13747L7.61105 3.19369C7.56102 3.14077 7.49158 3.11062 7.41868 3.11006C7.41037 3.10969 7.40197 3.10969 7.39367 3.11006L7.39367 3.11034Z"
                    fill="#68688C"
                    stroke="#68688C"
                    strokeWidth="0.4"
                  />
                </svg>
                <span>{t('common:logout')}</span>
              </a>
            </div>
            <div className={style.dropdownFooter}>
              <svg xmlns="http://www.w3.org/2000/svg" width="11" height="12" viewBox="0 0 11 12" fill="none">
                <path
                  d="M4.95 4.05801H6.05V2.95801H4.95M5.5 10.108C3.0745 10.108 1.1 8.13351 1.1 5.70801C1.1 3.28251 3.0745 1.30801 5.5 1.30801C7.9255 1.30801 9.9 3.28251 9.9 5.70801C9.9 8.13351 7.9255 10.108 5.5 10.108ZM5.5 0.208008C4.77773 0.208008 4.06253 0.35027 3.39524 0.62667C2.72795 0.903071 2.12163 1.3082 1.61091 1.81892C0.579463 2.85037 0 4.24932 0 5.70801C0 7.1667 0.579463 8.56565 1.61091 9.5971C2.12163 10.1078 2.72795 10.5129 3.39524 10.7893C4.06253 11.0657 4.77773 11.208 5.5 11.208C6.95869 11.208 8.35764 10.6285 9.38909 9.5971C10.4205 8.56565 11 7.1667 11 5.70801C11 4.98574 10.8577 4.27054 10.5813 3.60325C10.3049 2.93596 9.89981 2.32964 9.38909 1.81892C8.87837 1.3082 8.27205 0.903071 7.60476 0.62667C6.93747 0.35027 6.22227 0.208008 5.5 0.208008ZM4.95 8.45801H6.05V5.15801H4.95V8.45801Z"
                  fill="#68688C"
                />
              </svg>
              <span>Versiones</span>
              <p> 1.16.0</p>
            </div>
          </div>
        </div>
      )}
    </Navbar>
  )
}

export default MainNavbar
